import { Icon } from 'ysd-pp';
import styles from './index.module.less';
import classNames from 'classnames';
import ysBgLeftImg from '@/assets/yeastar/bg-left-circle.png';
import ysBgRightImg from '@/assets/yeastar/bg-right-circle.png';
import msBgRightImg from '@/assets/milesight/bg-right.png';
import ysLogoImg from '@/assets/yeastar/ys-logo-white.png';
import msLogoImg from '@/assets/milesight/ms-logo-white.png';

interface IProps {
    linkType?: string;
    detailInfo?: any;
}

const AppraisalsHeader = (props: IProps) => {
    const { linkType, detailInfo } = props;
    const descList = [
        {
            icon: 'calendar',
            title: `此链接${detailInfo?.effective_time}h内有效，且只能做一次`,
        },
        {
            icon: 'clock',
            title: `答题预计${detailInfo?.estimate_time}min（不超过${detailInfo?.maximum_time}min），过程中不能中断`,
        },
        {
            icon: 'mute',
            title: '请保证环境安静、网络稳定、时间充足不受干扰',
        },
    ];
    return (
        <div
            className={classNames(
                styles.container,
                styles[`container-${linkType}`],
                styles[detailInfo?.type === 1 ? 'mbti-container' : ''],
            )}
        >
            <div className={styles['wrap-content']}>
                <div className={styles.title}>{detailInfo?.title}</div>
                <div className={styles.subtitle}>
                    {detailInfo?.type === 1
                        ? '欢迎参加MBTI测评！完成后，您将立即获得个人报告，帮助您深入了解自己的个性特点和潜在优势。希望测评能带给您有意义和启发的体验！'
                        : `欢迎参加${detailInfo?.title}！为了更好地让我们认识和了解您，请完成此测评，感谢配合！`}
                </div>
                <div className={styles['desc-list']}>
                    {descList.map((item, index) => (
                        <div className={styles.descItem} key={index}>
                            <Icon type={item?.icon} className={styles.icon} />
                            <div className={styles['desc-title']}>{item.title}</div>
                            <div className={styles['background-filter']} />
                        </div>
                    ))}
                </div>
            </div>
            {linkType === 'yeastar' ? (
                <>
                    <img src={ysLogoImg} className={styles['ys-logo']} />
                    <img src={ysBgLeftImg} className={styles['ys-bg-left']} />
                    <img src={ysBgRightImg} className={styles['ys-bg-right']} />
                </>
            ) : (
                <>
                    <img src={msLogoImg} className={styles['ms-logo']} />
                    <img src={msBgRightImg} className={styles['ms-bg-right']} />
                </>
            )}
        </div>
    );
};

export default AppraisalsHeader;
