import styles from './index.module.less';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash';
import { useSize } from 'ahooks';

interface IProps {
    item?: any;
    handleOptionClick?: any;
}

const AnswerOptionsItem = (props: IProps) => {
    const { item, handleOptionClick } = props;

    const renderQuestionOptions = value => {
        const list = [
            'A.',
            'B.',
            'C.',
            'D.',
            'E.',
            'F.',
            'G.',
            'H.',
            'I.',
            'J.',
            'K.',
            'L.',
            'M.',
            'N.',
            'O.',
            'P.',
            'Q.',
            'R.',
            'S.',
            'T.',
            'U.',
            'V.',
            'W.',
            'X.',
            'Y.',
            'Z.',
        ];
        return list[value - 1] || '';
    };
    const textRef: any = useRef(null);
    const wrapRef: any = useRef(null);
    const maxFontSize = 16;
    const [hasChange, setHasChange] = useState(false);
    // 设置文本的字体大小

    const [fontSize, setFontSize] = useState(maxFontSize);
    const textWidth = useSize(textRef)?.width;
    const wrapWidth = useSize(wrapRef)?.width;

    const autoText = () => {
        // 判断当前容器与父容器的宽度大小
        // 当前容器宽度大于父容器宽度时，则减小字体大小
        if (textWidth && wrapWidth && textWidth > wrapWidth - 56) {
            let _fontSize = fontSize;
            _fontSize -= 1;
            setFontSize(_fontSize);
            setHasChange(true);
        } else if (!hasChange) {
            setFontSize(maxFontSize);
        }
    };
    useEffect(() => {
        setHasChange(false);
    }, [item?.item_name]);
    useEffect(() => {
        if (textWidth && wrapWidth) {
            autoText();
        }
    }, [textWidth, wrapWidth]);
    return (
        <div
            className={styles['options-item']}
            key={item?.item_number}
            onClick={() => debounce(handleOptionClick?.(item))}
            // onClick={handleOptionClick}
            ref={wrapRef}
        >
            <div className={styles['options-number']}>{renderQuestionOptions(item?.item_number)}</div>
            <div
                className={classNames(styles['options-label'])}
                style={{
                    fontSize: `${fontSize}px`,
                }}
                ref={textRef}
            >
                {item?.item_name}
            </div>
        </div>
    );
};

export default AnswerOptionsItem;
