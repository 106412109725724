import styles from "./index.module.less";
import classNames from "classnames";
import ProgressSlider from "../ProgressSlider";
import CountdownTimer from "../CountdownTimer";
import { useModel } from "@/components/useModel";

interface IProps {
    linkType?: string;
    currentNo: number;
    totalNo: number;
    setStatus?: (status: string) => void;
    startTime: Date;
}
const ProgressHeader = (props: IProps) => {
    const { linkType, currentNo, totalNo, setStatus, startTime } = props;
    const { detailInfo } = useModel("client");
    return (
        <div
            className={classNames(
                styles.container,
                styles[`container-${linkType}`]
            )}
        >
            <div className={styles["wrap-content"]}>
                <div className={styles.header}>
                    <div className={styles.title}>{detailInfo?.title}</div>
                    <div>
                        <CountdownTimer
                            startTime={startTime}
                            countdownMinutes={detailInfo?.maximum_time || 40}
                            // countdownMinutes={0.5}
                            linkType={linkType}
                            setStatus={setStatus}
                        />
                    </div>
                </div>
                <ProgressSlider
                    linkType={linkType}
                    value={(currentNo / totalNo) * 100}
                />
                <div>
                    <div>{`${currentNo}/${totalNo}`}</div>
                </div>
            </div>
        </div>
    );
};

export default ProgressHeader;
