import styles from './index.module.less';
import { Form, Icon, Input } from 'ysd-pp';
import yeastarButtonImg from '@/assets/yeastar/button.png';
import milesightButtonImg from '@/assets/milesight/button.png';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useRequest } from '@/utils/request';
import { apiUserPost } from '@/pages/client/services';
import type { TUserPostParams } from '@/pages/client/services';
interface IProps {
    linkType?: string;
    entrance?: string;
    detailInfo?: any;
    setMessage?: (message: string) => void;
    setVisible?: (visible: boolean) => void;
}
const UserForm = (props: IProps) => {
    const { linkType, entrance, detailInfo, setMessage, setVisible } = props;
    const [form] = Form.useForm();
    const navigate = useNavigate();

    let timer1: any;
    let error1 = '';
    let timer2: any;
    let error2 = '';

    const isMobilePhone = (value: any) => {
        const reg = /^1\d{10}$/;
        return reg.test(value);
    };

    const checkMobilePhone = (_rule: any, value: any) => {
        const message = '请输入一个正确的手机号';
        if (timer2) {
            clearTimeout(timer2);
        }
        return new Promise((resolve, reject) => {
            timer2 = setTimeout(
                () => {
                    try {
                        if (value && !isMobilePhone(value)) {
                            reject(message);
                            error1 = message;
                        } else {
                            resolve('');
                            error1 = '';
                        }
                    } catch (e) {
                        reject(message);
                    }
                },
                error1 ? 0 : 300,
            );
        });
    };
    const isIdCard = (value: any) => {
        const reg = /^\d{15,17}[\dXx]$/;
        return reg.test(value);
    };

    const checkIdCard = (_rule: any, value: any) => {
        const message = '请输入一个正确的身份证号码';
        if (timer1) {
            clearTimeout(timer1);
        }
        return new Promise((resolve, reject) => {
            timer1 = setTimeout(
                () => {
                    try {
                        if (value && !isIdCard(value)) {
                            reject(message);
                            error2 = message;
                        } else {
                            resolve('');
                            error2 = '';
                        }
                    } catch (e) {
                        reject(message);
                    }
                },
                error2 ? 0 : 300,
            );
        });
    };
    const { runAsync: startAnswer } = useRequest((obj: TUserPostParams) => {
        return apiUserPost(obj).then(res => {
            return res;
        });
    });
    const onFinish = () => {
        form.validateFields().then(async values => {
            values.entrance = entrance;
            const res = await startAnswer(values as TUserPostParams);
            if (res?.code === 200) {
                if (res?.data?.entrance_id) {
                    navigate(`/appraisal?entrance=${res?.data?.entrance_id}`);
                } else {
                    localStorage.setItem(
                        'answerObj',
                        JSON.stringify({
                            entrance,
                            startTime: new Date(),
                            detailInfo: detailInfo,
                        }),
                    );
                    navigate(`/appraisal?entrance=${entrance}`);
                }
            } else {
                setVisible?.(true);
                setMessage?.(res?.message);
            }
        });
    };
    return (
        <div
            className={classNames(
                styles.container,
                styles[`container-${linkType}`],
                styles[detailInfo?.type === 1 ? 'mbti-container' : ''],
            )}
        >
            <div className={styles['wrap-content']}>
                <div className={styles.title}>请在输入信息后，点击开始答题</div>
                <Form form={form}>
                    <Form.Item
                        name="name"
                        validateFirst
                        rules={[{ required: true, message: '请输入一个正确的姓名' }]}
                        // validateTrigger={['onBlur', 'onChange']}
                    >
                        <Input placeholder="姓名" prefix={<Icon type="personal-information" />} />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '请输入一个正确的手机号',
                            },
                            {
                                validator: checkMobilePhone,
                            },
                        ]}
                    >
                        <Input placeholder="手机号" type="tel" prefix={<Icon type="mobile" />} />
                    </Form.Item>
                    <Form.Item
                        name="id_card"
                        rules={[
                            {
                                required: true,
                                message: '请输入一个正确的身份证号码',
                            },
                            {
                                validator: checkIdCard,
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input placeholder="身份证号" type="tel" prefix={<Icon type="id-card" />} />
                    </Form.Item>
                </Form>
                <div className={classNames(styles['bottom-button'])} onClick={onFinish}>
                    <div className={styles['button-text-left']}>开 始</div>
                    {
                        <img
                            src={linkType === 'yeastar' ? yeastarButtonImg : milesightButtonImg}
                            className={styles['button-img']}
                        />
                    }
                    <div className={styles['button-text-right']}>答 题</div>
                </div>
                <div className={styles['tip-text']}>您所有信息我们将保密，数据仅限于我们内部使用</div>
            </div>
        </div>
    );
};

export default UserForm;
