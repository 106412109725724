import { useEffect, useState } from "react";
// import { Statistic } from "ysd-pp";

interface IProps {
    startTime: Date;
    countdownMinutes: number;
    linkType?: string;
    setStatus?: (status: string) => void;
}
import styles from "./index.module.less";
import classNames from "classnames";
import { apiOverduePost } from "@/pages/client/services";

import { useSearchParams } from "react-router-dom";
import { useRequest } from "@/utils/request";
const CountdownTimer = (props: IProps) => {
    const { startTime, countdownMinutes, linkType, setStatus } = props;
    // const { Countdown } = Statistic;
    // const [timeRemaining, setTimeRemaining] = useState({
    //   minutes: countdownMinutes,
    //   seconds: 0,
    // });
    const [searchParams] = useSearchParams();
    const entrance = searchParams.get("entrance") || "";
    const [minutesList, setMinutesList] = useState<string[]>([]);
    const [secondsList, setSecondsList] = useState<string[]>([]);
    const { runAsync: runOverduePost } = useRequest(() => {
        return apiOverduePost({ entrance_id: entrance })?.then((res) => {
            return res;
        });
    });
    useEffect(() => {
        const endTime = new Date(
            new Date(startTime)?.getTime() + countdownMinutes * 60000
        );

        const timer = setInterval(() => {
            const now = new Date();
            const remainingTime =
                Math.max(0, endTime.getTime() - now.getTime()) / 1000;

            const minutes = Math.floor(remainingTime / 60);
            const seconds = Math.floor(remainingTime % 60);

            // setTimeRemaining({ minutes, seconds });
            const _minutesList = minutes.toString().padStart(2, "0").split("");
            const _secondsList = seconds.toString().padStart(2, "0").split("");
            setMinutesList(_minutesList);
            setSecondsList(_secondsList);
            if (remainingTime <= 0) {
                clearInterval(timer);
                setStatus?.("timeout");
                runOverduePost();
                localStorage.removeItem("answerObj");
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [startTime, countdownMinutes]);
    return (
        <div
            className={classNames(
                styles["cutdown-item"],
                styles[linkType === "yeastar" ? "" : "ms-cutdown-item"]
            )}
        >
            {/* <Countdown value={endTime?.getTime()} format="mm:ss" /> */}
            <div className={styles["minute-item"]}>
                {!!minutesList?.length &&
                    minutesList?.map((item, index) => (
                        <span key={index} className={styles["time-item"]}>
                            {item}
                        </span>
                    ))}
            </div>
            <div className={styles["time-colon"]}>:</div>
            <div className={styles["second-item"]}>
                {!!secondsList?.length &&
                    secondsList?.map((item, index) => (
                        <span key={index} className={styles["time-item"]}>
                            {item}
                        </span>
                    ))}
            </div>
        </div>
    );
};

export default CountdownTimer;
