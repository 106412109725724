import { EAppraisalLinkType } from "@/utils/constants";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BlankLayout from "./BlankLayout";
import "./ClientLayout.less";

export const Context = React.createContext<{
    appraisalLinkType: EAppraisalLinkType;
    layoutClassName?: string;
    setLayoutClassName?: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
}>({
    appraisalLinkType: EAppraisalLinkType.yeastar,
});

function ClientLayout({ linkType }: { linkType: EAppraisalLinkType }) {
    const [layoutClassName, setLayoutClassName] = useState<string>();
    const location = useLocation();
    // 解决跳转新页面滚动条不在顶部的问题
    useEffect(() => {
        window.scroll({ top: 0, behavior: "smooth" });
    }, [location]);

    if (["/404"].includes(location.pathname)) {
        return <BlankLayout />;
    }

    return (
        <Context.Provider
            value={{
                appraisalLinkType: linkType,
                layoutClassName,
                setLayoutClassName,
            }}
        >
            <section
                className={classNames(
                    "layout-client",
                    `layout-client-${linkType}`,
                    layoutClassName
                )}
            >
                <Outlet />
            </section>
        </Context.Provider>
    );
}

export default ClientLayout;
