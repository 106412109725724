import request from '@/utils/request';

const MOCK_PREFIX = '/oa_web_survey';

/**
 * 获取测评详情
 * get请求参数类型
 * https://app.apifox.com/project/3297894/apis/api-110801318
 */
export type TSurveyDetailGetParams = {
    /** 入口标识 */
    entrance_id?: string;
};
/**
 * 获取测评详情
 * response body类型
 * https://app.apifox.com/project/3297894/apis/api-110801318
 */
export type TSurveyDetailGetRes = {
    /** 测评类型 0：16PF 1：MBTI 2：高危   */
    type?: number;
    /**  */
    title?: string;
    /**  */
    effective_time?: number;
    /**  */
    estimate_time?: number;
    /**  */
    maximum_time?: number;
    /** 答题状态 */
    status?: number;
    /**  */
    qnf_info?: {
        /**  */
        qnf_number?: number;
        /**  */
        qnf_title?: string;
        /**  */
        item_info?: {
            /**  */
            item_number?: number;
            /**  */
            item_name?: string;
            /**  */
            item_score?: number;
        }[];
    }[];
};
/**
 * 检查入口有效性
 * https://app.apifox.com/link/project/3297894/apis/api-117211290
 */
export const apiCheckEntranceGet = (params: TSurveyDetailGetParams) => {
    return request<TSurveyDetailGetRes>(`${MOCK_PREFIX}/public/client/check_entrance`, {
        method: 'GET',
        params,
    });
};
/**
 * 获取测评详情
 * https://app.apifox.com/project/3297894/apis/api-110801318
 */
export const apiSurveyDetailGet = (params: TSurveyDetailGetParams) => {
    return request<TSurveyDetailGetRes>(`${MOCK_PREFIX}/public/client/get_entrance_survey_detail`, {
        method: 'GET',
        params,
    });
};
// /**
//  * 获取测评详情
//  * https://app.apifox.com/project/3297894/apis/api-110801318
//  */
// export const apiSurveyDetailGet = (params: TSurveyDetailGetParams) => {
//     return request<TSurveyDetailGetRes>(`${MOCK_PREFIX}/survey/detail`, {
//         method: "GET",
//         params,
//     });
// };

/**
 * 添加答题用户信息
 * https://app.apifox.com/project/3297894/apis/api-110803015
 */
export interface TUserPostParams {
    /**
     * 入口标识
     */
    entrance: string;
    /**
     * 身份证号
     */
    id_card: string;
    /**
     * 姓名
     */
    name: string;
    /**
     * 手机号
     */
    phone: string;
}

/**
 * 添加答题用户信息
 * https://app.apifox.com/project/3297894/apis/api-110803015
 */
export const apiUserPost = (data: TUserPostParams) => {
    return request(`${MOCK_PREFIX}/public/client/start_answer`, {
        method: 'POST',
        data,
    });
};

/**
 * 提交答案
 * request body类型
 * https://app.apifox.com/project/3297894/apis/api-110803619
 */
export type TAnswerPostParams = {
    /**  */
    entrance_id?: string;
    /**  */
    use_time?: number;
    /**  */
    answer_info?: {
        /**  */
        qnf_number?: number;
        /**  */
        item_number?: number;
    }[];
};

/**
 * 提交答案
 * https://app.apifox.com/project/3297894/apis/api-110803619
 */
export const apiAnswerPost = (data: TAnswerPostParams) => {
    return request(`${MOCK_PREFIX}/public/client/submit_answer`, {
        method: 'POST',
        data,
    });
};

export type TOverdueParams = {
    /** 入口标识 */
    entrance_id?: string;
};
/**
 * 超时请求
 * https://app.apifox.com/link/project/3297894/apis/api-117211294
 */
export const apiOverduePost = (data: TOverdueParams) => {
    return request(`${MOCK_PREFIX}/public/client/overdue/${data?.entrance_id}`, {
        method: 'PUT',
        data,
    });
};

export type TReportAssetsParams = {
    /** 入口标识 */
    entranceId?: string;
    /** 测评类型 */
    surveyType?: string;
    /** 报告类型 */
    type?: string;
    /** 强制刷新 */
    force?: string;
};

/**
 * 获取报告资源图片
 * https://app.apifox.com/link/project/3297894/apis/api-127584036
 */
export const apiGetReportAssets = (params: TReportAssetsParams) => {
    return request(
        `${MOCK_PREFIX}/public/client/report_assets/${params?.surveyType}/${params?.type}/${params?.entranceId}`,
        {
            method: 'GET',
            params: { force: params?.force },
        },
    );
};
