import yeastarQuestionImg from '@/assets/yeastar/question.png';
import milesightBgImg from '@/assets/milesight/question-bg.png';

import styles from './index.module.less';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
// import { useRequest } from '@/utils/request';
import { apiAnswerPost } from '@/pages/client/services';
// import { Button } from "ysd-pp";
import Toast from '@/pages/components/Toast';
import AnswerOptionsItem from '../AnswerOptionsItem';
// import { Button } from "ysd-pp";
// import { array2 } from "./test";
// import { array1, array2, array3 } from "./test";

interface IProps {
    linkType?: string;
    questions?: any;
    currentQuestion: number;
    setCurrentQuestion?: (currentQuestion: number) => void;
    setStatus?: (status: string) => void;
    entrance?: string;
    oldAnswer?: any[];
    type?: number;
    questionsRef?: any;
}

const Question = (props: IProps) => {
    const {
        questions,
        currentQuestion,
        setCurrentQuestion,
        setStatus,
        entrance,
        oldAnswer,
        type,
        questionsRef,
        linkType,
    } = props;
    // const [currentQuestion, setCurrentQuestion] = useState(0);
    // const [selectedOption, setSelectedOption] = useState("");
    const [answer, setAnswer] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    // const { runAsync: runSubmitAnswer } = useRequest(apiAnswerPost);
    useEffect(() => {
        setAnswer(oldAnswer || []);
    }, [oldAnswer]);
    const saveAnswer = option => {
        const obj = JSON.parse(localStorage.getItem('answerObj') || '{}');
        const _answer = [...answer] as any[];
        const index = _answer.findIndex(obj => obj.qnf_number === questions?.[currentQuestion]?.qnf_number);
        const currentAnswer = {
            qnf_number: questions?.[currentQuestion]?.qnf_number,
            item_number: option?.item_number,
        };
        if (index !== -1) {
            // Replace the existing object
            _answer[index] = currentAnswer;
        } else {
            // Add a new object
            _answer.push(currentAnswer);
        }
        setAnswer(_answer);
        if (obj?.entrance === entrance) {
            localStorage.setItem(
                'answerObj',
                JSON.stringify({
                    ...obj, // 之前的答案
                    answer_info: [..._answer], // 当前答案
                    currentQuestion: currentQuestion + 1,
                }),
            );
        }
        return _answer;
    };
    const loadingRef = useRef(false);

    // 提交答案
    const submitAnswer = async params => {
        const res = await apiAnswerPost(params);
        if (res?.code === 200) {
            localStorage.removeItem('answerObj');
            loadingRef.current = false;
            if (type === 1) {
                setStatus?.('loading');
            } else {
                setStatus?.('finished');
            }
        } else {
            setVisible?.(true);
            setMessage?.(res?.message);
            loadingRef.current = false;
        }
    };
    const handleOptionClick = (option: any) => {
        const obj = JSON.parse(localStorage.getItem('answerObj') || '{}');
        const _answer = saveAnswer(option);

        if (currentQuestion < questions.length - 1) {
            setTimeout(() => {
                setCurrentQuestion?.(currentQuestion + 1);
            }, 500); // 延迟0.5秒跳转到下一题
        } else {
            if (!loadingRef.current) {
                let use_time = 0;
                use_time = Math.floor((new Date().getTime() - new Date(obj?.startTime)?.getTime()) / 1000);
                localStorage.setItem(
                    'answerObj',
                    JSON.stringify({
                        ...obj, // 之前的答案
                        use_time: use_time, //第一次答题所用时间
                    }),
                );

                loadingRef.current = true;
                submitAnswer({
                    entrance_id: entrance,
                    answer_info: _answer,
                    use_time,
                });
            }
        }
    };
    // const finishAnswer = () => {
    //     const objectArray = [] as any;
    //     for (let i = 1; i <= questions?.length; i++) {
    //         const itemNumber = Math.floor(Math.random() * 2) + 1;
    //         const obj = {
    //             qnf_number: i,
    //             item_number: itemNumber,
    //         };
    //         objectArray.push(obj);
    //     }
    //     // objectArray = array2;
    //     setCurrentQuestion?.(questions.length - 1);
    //     setAnswer(objectArray);
    // };
    // const finishAnswer1 = () => {
    //   let objectArray = [] as any;
    //   objectArray = array1;

    //   setCurrentQuestion?.(questions.length - 1);
    //   setAnswer(objectArray);
    // };
    // const finishAnswer2 = () => {
    //   let objectArray = [] as any;
    //   objectArray = array3;

    //   setCurrentQuestion?.(questions.length - 1);
    //   setAnswer(objectArray);
    // };

    return (
        <div className={classNames(styles.container, styles[`container-${linkType}`])} ref={questionsRef}>
            <div className={styles['wrap-content']}>
                <div className={styles['question-item']}>
                    <div className={styles['question-no']}>Q {questions?.[currentQuestion]?.qnf_number}</div>
                    <div className={styles['question-content']}>{questions?.[currentQuestion]?.qnf_title}</div>
                    <div className={styles['question-start-icon']}>"</div>
                    <div className={styles['question-end-icon']}>"</div>
                    <div className={styles['question-bottom-item']} />
                    {linkType === 'yeastar' ? (
                        <img src={yeastarQuestionImg} className={styles['question-img']} />
                    ) : (
                        <img src={milesightBgImg} className={styles['ms-question-bg-top']} />
                    )}
                </div>

                {questions?.[currentQuestion]?.item_info?.map((item: any) => (
                    <AnswerOptionsItem item={item} key={item?.item_number} handleOptionClick={handleOptionClick} />
                ))}
            </div>
            {/* <Button className={styles['button']} onClick={finishAnswer}>
                生成数据
            </Button> */}
            {/* <Button className={styles["button"]} onClick={finishAnswer}>
        生成小C数据
      </Button>
      <Button
        onClick={() => {
          setCurrentQuestion?.(176);
        }}
      >
        电梯入口
      </Button>
      <Button className={styles["button"]} onClick={finishAnswer1}>
        生成小B数据
      </Button>
      <Button className={styles["button"]} onClick={finishAnswer2}>
        生成小D数据
      </Button> */}
            <Toast visible={loadingRef.current} type="loading" message="提交中" />
            <Toast visible={visible} message={message} setVisible={setVisible} />
        </div>
    );
};

export default Question;
