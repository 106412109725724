import { Icon } from 'ysd-pp';

import styles from './index.module.less';
import { useEffect } from 'react';
import classNames from 'classnames';

interface IProps {
    visible?: boolean;
    icon?: string;
    message?: string;
    duration?: number;
    setVisible?: (visible: boolean) => void;
    type?: string;
}
const Toast = (props: IProps) => {
    const {
        visible,
        duration = 3000,
        setVisible,
        type = 'info',
        icon = type === 'loading' ? 'loading' : '',
        message = type === 'loading' ? '加载中' : '',
    } = props;

    useEffect(() => {
        if (visible && duration && type !== 'loading') {
            setTimeout(() => {
                setVisible?.(false);
            }, duration);
        }
    }, [visible, duration]);
    return (
        <div className={classNames(styles['toast-modal'], styles[visible ? '' : 'toast-modal-hide'])}>
            <div className={styles['toast-modal-content']}>
                <Icon type={icon || 'info'} spin={type === 'loading'} className={styles['toast-icon']} />
                <div className={styles['message']}>{message}</div>
            </div>
        </div>
    );
};

export default Toast;
