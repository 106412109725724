import styles from './index.module.less';
import classNames from 'classnames';
import ysSuccessImg from '@/assets/yeastar/suc.png';
import msSuccessImg from '@/assets/milesight/suc.png';
import ysFailImg from '@/assets/yeastar/fail.png';
import msFailImg from '@/assets/milesight/fail.png';
import ysBgLeftImg from '@/assets/yeastar/bg-left-circle.png';
import ysBgRightImg from '@/assets/yeastar/bg-right-circle.png';
import msBgRightImg from '@/assets/milesight/bg-right.png';
import meilsightBgBottomImg from '@/assets/milesight/bg-bottom.png';
import { useMemo } from 'react';
import { useModel } from '@/components/useModel';
import moment from 'moment';

interface IProps {
    linkType?: string;
    status?: string;
}
const AppraisalsHeader = (props: IProps) => {
    const { detailInfo } = useModel('client');
    const { linkType, status } = props;
    const resultImg = useMemo(() => {
        if (status === 'finished') {
            return linkType === 'yeastar' ? ysSuccessImg : msSuccessImg;
        } else {
            return linkType === 'yeastar' ? ysFailImg : msFailImg;
        }
    }, [linkType, status]);
    const resultTitle = useMemo(() => {
        switch (status) {
            case 'finished':
                return '太棒了，您已成功提交！';
            case 'timeout':
                return '倒计时结束';
            case 'expired':
                return '链接失效';
            case 'fail':
                return '生成失败';
            default:
                return '访问失败';
        }
    }, [status]);
    const resultDesc = useMemo(() => {
        switch (status) {
            case 'finished':
                return '非常感谢您认真完成我们的性格测评！现在请告知我们HR，这样我们就能迅速生成测评报告啦！报告出来后，我们将尽快与您联系！';
            case 'timeout':
                return '很遗憾，由于时间限制，答题已关闭。如有任何疑问或建议，请随时与HR联系。';
            case 'expired':
                return '此链接已失效，请联系HR。';
            case 'fail':
                return '报告生成失败，请刷新重试或联系HR';
            case 'invalid':
                return '当前时间非测评期，如有疑问请联系HR';
            default:
                return '系统遇到一些问题，如需帮助请联系HR';
        }
    }, [status]);
    return (
        <>
            <div className={classNames(styles.container, styles[`container-${linkType}`])}>
                <div className={styles['wrap-header']}>
                    <div className={styles['wrap-content']}>
                        <div className={styles.title}>{detailInfo?.title}</div>
                        <div className={styles['result-content']}>
                            <img src={resultImg} className={styles['result-img']} />
                            <div className={styles['result-title']}>{resultTitle}</div>
                            <div className={styles['result-desc']}>{resultDesc}</div>
                            <div className={styles.arrow}></div>
                        </div>
                    </div>
                    {linkType === 'yeastar' ? (
                        <>
                            <img src={ysBgLeftImg} className={styles['ys-bg-left']} />
                            <img src={ysBgRightImg} className={styles['ys-bg-right']} />
                        </>
                    ) : (
                        <img src={msBgRightImg} className={styles['ms-bg-right']} />
                    )}
                </div>
                <div className={styles['result-footer']}>
                    <div className={styles['result-name']}>星纵人力资源部</div>
                    <div className={styles['result-date']}>{moment(new Date()).format('YYYY年MM月')}</div>
                    <img src={meilsightBgBottomImg} className={styles['ms-bg-bottom']} />
                </div>
            </div>
        </>
    );
};

export default AppraisalsHeader;
