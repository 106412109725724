import { useNavigate, useSearchParams } from 'react-router-dom';
import AppraisalsHeader from './components/AppraisalsHeader';
import UserForm from './components/UserForm';

import styles from './index.module.less';
import { useModel } from '@/components/useModel';
import { useContext, useEffect, useState } from 'react';
import { apiCheckEntranceGet } from '../services';
import { useRequest } from '@/utils/request';
import Toast from '@/pages/components/Toast';
import { Context } from '@/layouts/ClientLayout';

function Welcome() {
    const { appraisalLinkType: linkType } = useContext(Context);
    const [searchParams] = useSearchParams();
    const entrance = searchParams.get('entrance') || '';
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [data, setData] = useState<any>({});
    const { runAsync: runCheckEntrance } = useRequest(id => {
        return apiCheckEntranceGet({ entrance_id: id });
    });
    const { setDetailInfo } = useModel('client', model => ({
        setDetailInfo: model.setDetailInfo,
    }));
    // 强制刷新
    const force = searchParams.get('force') || '';
    const checkEntrance = async () => {
        const res = await runCheckEntrance(entrance);
        // 测评链接有效，开始答题
        if (res?.code === 200) {
            setData(res?.data);
            // mbti 测评且完成答题
            if (res?.data?.type === 1 && res?.data?.status === 2) {
                navigate(force ? `/appraisal?entrance=${entrance}&force=${force}` : `/appraisal?entrance=${entrance}`);
            }
        } else {
            // 测评链接无效，跳转到错误页面
            navigate(`/appraisal?entrance=${entrance}`);
        }
    };
    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem('answerObj') || '{}');
        // 如果本地缓存有数据，且入口id一致，直接跳转到答题页面
        if (obj?.entrance === entrance) {
            setDetailInfo(obj?.detailInfo);
            navigate(`/appraisal?entrance=${entrance}`);
        } else {
            // 如果本地缓存没有数据，或者入口id不一致，检查入口有效性
            checkEntrance();
        }
    }, [entrance]);
    return (
        <div className={styles.container}>
            <AppraisalsHeader linkType={linkType} detailInfo={data} />
            <UserForm
                linkType={linkType}
                entrance={entrance}
                detailInfo={data}
                setMessage={setMessage}
                setVisible={setVisible}
            />
            <Toast visible={visible} message={message} setVisible={setVisible} />
        </div>
    );
}

export default Welcome;
