import classNames from 'classnames';
import ProgressHeader from './components/ProgressHeader';

import styles from './index.module.less';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Question from './components/Questions';
import yeastarBgLeftImg from '@/assets/yeastar/question-bg-left.png';
import yeastarBgRightImg from '@/assets/yeastar/question-bg-right.png';
import meilsightBgBottomImg from '@/assets/milesight/bg-bottom.png';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AppraisalsWrap from './components/AppraisalsWrap';
import { useModel } from '@/components/useModel';
import { useRequest } from '@/utils/request';
import {
    TReportAssetsParams,
    apiCheckEntranceGet,
    apiGetReportAssets,
    apiOverduePost,
    apiSurveyDetailGet,
} from '../services';
import { Context } from '@/layouts/ClientLayout';
import loadingMs from '@assets/images/loading-ms.gif';
import loadingYs from '@assets/images/loading-ys.gif';
import { Spin } from 'ysd-pp';
import { DownloadOutlined } from 'ysd-pp/es/icon';
import axios from 'axios';
import Toast from '@/pages/components/Toast';
import { EAppraisalLinkType } from '@/utils/constants';

function Appraisal() {
    const { appraisalLinkType: linkType } = useContext(Context);
    const [searchParams] = useSearchParams();
    const entrance = searchParams.get('entrance') || '';
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const { detailInfo } = useModel('client');
    const [questions, setQuestions] = useState(detailInfo?.qnf_info);
    const [startTime, setStartTime] = useState(new Date());
    const [status, setStatus] = useState('');
    const [oldAnswer, setOldAnswer] = useState<any[]>([]);
    const [resultImg, setResultImg] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const navigate = useNavigate();

    const { runAsync: getDetail } = useRequest(id => {
        return apiSurveyDetailGet({ entrance_id: id })?.then(res => {
            return res;
        });
    });

    // 强制刷新
    const force = searchParams.get('force') || '';

    // 检查入口有效性
    const { runAsync: runCheckEntrance } = useRequest(id => {
        return apiCheckEntranceGet({ entrance_id: id });
    });
    const { setDetailInfo } = useModel('client', model => ({
        setDetailInfo: model.setDetailInfo,
    }));
    // 提交过期
    const { runAsync: runOverduePost } = useRequest(() => {
        return apiOverduePost({ entrance_id: entrance })?.then(res => {
            return res;
        });
    });
    // 获取本地缓存
    const getStorage = async () => {
        const obj = JSON.parse(localStorage.getItem('answerObj') || '{}');
        setDetailInfo(obj?.detailInfo);
        // 如果链接中携带invalid说明测评不在有效期,需要提示不在有效期
        if (entrance === 'invalid') {
            setStatus('invalid');
            return;
        }
        if (obj?.entrance === entrance) {
            setCurrentQuestion(obj?.currentQuestion || 0);
            // setQuestions(obj?.detailInfo?.qnf_info || []);
            setStartTime(obj?.startTime || new Date());
            setOldAnswer(obj?.answer_info || []);
            // 是否获取到答题信息
            if (obj?.detailInfo?.qnf_info && obj?.detailInfo?.qnf_info?.length) {
                setQuestions(obj?.detailInfo?.qnf_info || []);
            } else {
                getDetail(entrance).then(res => {
                    if (res?.code === 200) {
                        setQuestions(res?.data?.qnf_info || []);
                        obj.detailInfo = res?.data;
                        setDetailInfo(res?.data);
                        localStorage.setItem('answerObj', JSON.stringify(obj));
                    } else {
                        setStatus('error');
                    }
                });
            }
            // 是否答题超时
            if (
                obj?.startTime &&
                obj?.detailInfo?.maximum_time &&
                new Date().getTime() - new Date(obj?.startTime)?.getTime() < 60000 * obj?.detailInfo?.maximum_time
            ) {
                setStatus('started');
            } else {
                setStatus('timeout');
                runOverduePost();
            }
        } else {
            // 检查入口有效性
            const res = await runCheckEntrance(entrance);
            if (res?.code === 200) {
                if (res?.data?.type === 1 && res?.data.status === 2) {
                    setStatus('loading');
                } else {
                    // setStartTime(new Date());
                    // setStatus('started');
                    navigate(`/welcome?entrance=${entrance}`);
                }
            } else if (res?.code === 2104) {
                // 测评过期
                setStatus('expired');
            } else {
                setStatus('error');
            }
        }
    };

    useEffect(() => {
        getStorage();
    }, []);

    let flag = '';
    let restTime = 60;
    const imgRef = useRef('');

    const cutDown = () => {
        const timer = setTimeout(() => {
            // 60秒超时未响应
            if (restTime <= 0) {
                setStatus('fail');
                flag = 'fail';
                clearTimeout(timer);
            } else {
                restTime--;
                // 生成报告成功则取消倒计时
                if (!imgRef?.current) {
                    cutDown();
                }
            }
        }, 1000);
    };

    useEffect(() => {
        if (status === 'loading') {
            cutDown();
            const params = {
                surveyType: detailInfo?.type || 1,
                type: linkType === 'yeastar' ? 'ys' : 'ms',
                entranceId: entrance,
            } as TReportAssetsParams;
            if (force) {
                params.force = force;
            }
            // 请求生成报告
            getReportImg(params);
        }
    }, [status]);

    const getReportImg = params => {
        setLoading(true);
        apiGetReportAssets(params)
            .then((res: any) => {
                if (res?.code === 200) {
                    const src = res?.data?.assets?.[0];
                    setResultImg?.(src);
                    setLoading(false);
                    setStatus('');
                    imgRef.current = src;
                } else {
                    if (flag === 'fail') {
                        setLoading(false);
                        return;
                    }
                    setTimeout(() => {
                        getReportImg(params);
                    }, 1000);
                }
            })
            .catch(() => {
                if (flag === 'fail') {
                    setLoading(false);
                    return;
                }
                setTimeout(() => {
                    getReportImg(params);
                }, 1000);
            });
    };

    const pdfSRC = useMemo(() => {
        return {
            src: `/web/viewer.html?file=${encodeURIComponent(resultImg)}`,
            url: resultImg.replace('./', '/web/'),
        };
    }, [resultImg]);

    const [isMobileDevice] = useState<boolean>(() => {
        return /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|MicroMessenger|wxwork)/i.test(
            window.navigator.userAgent,
        );
    });
    const downloadPDFs = () => {
        if (isMobileDevice) {
            const dom = document.createElement('a');
            dom.href = pdfSRC.url;
            dom.download = `MBTI测评报告-${entrance}.pdf`;
            dom.target = '_blank';
            dom.style.display = 'none';
            document.body.appendChild(dom);
            dom.click();
            dom.parentNode?.removeChild(dom);
        } else {
            axios
                .get(pdfSRC.url, {
                    responseType: 'blob',
                })
                .then(res => {
                    const blob = new Blob([res.data], {
                        type: 'application/pdf',
                    });
                    const dom = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    dom.href = url;
                    dom.download = `MBTI测评报告-${entrance}.pdf`;
                    dom.style.display = 'none';
                    document.body.appendChild(dom);
                    dom.click();
                    dom.parentNode?.removeChild(dom);
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    console.log(err);
                    setVisible(true);
                    setMessage('下载失败');
                });
        }
    };
    const [showedDownloadBtn, setShowedDownloadBtn] = useState<boolean>(false);
    useEffect(() => {
        window['showDownloadBtn'] = () => {
            setShowedDownloadBtn(true);
        };
    }, []);

    return (
        <div className={classNames(styles.container, styles[`container-${linkType}`])}>
            {!loading && status && status === 'started' ? (
                <div className={styles['wrap-content']}>
                    <ProgressHeader
                        currentNo={currentQuestion + 1 < questions?.length ? currentQuestion + 1 : questions?.length}
                        totalNo={questions?.length}
                        linkType={linkType}
                        setStatus={setStatus}
                        startTime={startTime}
                    />
                    <Question
                        linkType={linkType}
                        questions={questions}
                        currentQuestion={currentQuestion}
                        setCurrentQuestion={setCurrentQuestion}
                        setStatus={setStatus}
                        entrance={entrance}
                        oldAnswer={oldAnswer}
                        type={detailInfo?.type || 0}
                    />
                    {linkType === 'yeastar' ? (
                        <>
                            <img src={yeastarBgLeftImg} className={styles['ys-bg-left']} />
                            <img src={yeastarBgRightImg} className={styles['ys-bg-right']} />
                        </>
                    ) : (
                        <img src={meilsightBgBottomImg} className={styles['ms-bg-bottom']} />
                    )}
                </div>
            ) : !loading && status ? (
                <AppraisalsWrap linkType={linkType} status={status} />
            ) : !loading && resultImg ? (
                <>
                    <iframe className={styles['pdf-viewer']} src={pdfSRC.src} />
                    {showedDownloadBtn ? (
                        <div className={styles['download-icon-wrap']}>
                            <DownloadOutlined
                                type="icon-filedownload-f"
                                className={styles['download-icon']}
                                onClick={downloadPDFs}
                            />
                        </div>
                    ) : null}
                    <Toast visible={visible} message={message} setVisible={setVisible} />
                </>
            ) : (
                <div className={styles['spin-wrap']}>
                    <Spin
                        tip="报告生成中...请稍后"
                        indicator={
                            <img
                                src={linkType === EAppraisalLinkType.milesight ? loadingMs : loadingYs}
                                alt="loading"
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default Appraisal;
