import classNames from 'classnames';
import { useContext } from 'react';

import styles from './index.module.less';
import { Context } from '@/layouts/ClientLayout';
import AppraisalsWrap from './components/AppraisalsWrap';

function Fail() {
    const { appraisalLinkType: linkType } = useContext(Context);
    return (
        <div className={classNames(styles.container, styles[`container-${linkType}`])}>
            <AppraisalsWrap linkType={linkType} status={'fail'} />
        </div>
    );
}

export default Fail;
