import { Slider } from "ysd-pp";
import styles from "./index.module.less";
import classNames from "classnames";

interface IProps {
  linkType?: string;
  value?: number;
}
const ProgressSlider = (props: IProps) => {
  const { linkType, value } = props;
  return (
    <div
      className={classNames(styles.container, styles[`container-${linkType}`])}
    >
      <Slider value={value} className={styles["progress-slider"]} disabled />
    </div>
  );
};

export default ProgressSlider;
